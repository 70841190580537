import { Link } from "react-router-dom";
import byMeCoffee from "./../assets/by-me-a-coffee-violet.webp";

export const Footer: React.FC = () => {
  return (
    <footer className="absoule bottom-0 lg:w-[80%] m-auto flex justify-end mb-4 z-20">
      <ul className="flex flex-wrap items-center cursor-pointer text-sm md:text-base px-4">
        <li className="px-3 hover:text-gray-600 my-1">
          <Link to="/privacy-policy">Privacy Policy</Link>
        </li>
        <li className="px-6 hover:text-gray-600 my-1">
          <Link to="/user-guide">User guide</Link>
        </li>
        <li className="text-tertiary font-bold w-full md:w-auto mr-3 my-1">
          <a href="mailto:app@carbsonplate.com" className="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 mx-1"
            >
              <path
                strokeLinecap="round"
                d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25"
              />
            </svg>
            app@carbsonplate.com
          </a>
        </li>
        <li className="px-3 my-1" title="Support Project">
          <a href="https://www.buymeacoffee.com/carbs.on.plate">
            <img
              width="150"
              height="42"
              src={byMeCoffee}
              alt="by me a coffee button"
            />
          </a>
        </li>
      </ul>
    </footer>
  );
};
