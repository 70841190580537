interface Props {
  children: React.ReactNode;
}

export const Main: React.FC<Props> = ({ children }) => {
  return (
    <main className="mt-[65px] flex-1 z-20">
      <div className="lg:w-[80%] mx-auto p-4 md:p-12">{children}</div>
    </main>
  );
};
