import { Footer } from "../components/Footer";
import { Navbar } from "../components/Navbar";
import { UserGuideContent } from "../components/UserGuideContent";

export const UserGuidePage: React.FC = () => {
  return (
    <>
      <Navbar />
      <UserGuideContent />
      <Footer />
    </>
  );
};
