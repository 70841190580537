import { Link } from "react-router-dom";
import logo from "./../assets/cop.webp";

export const Navbar: React.FC = () => {
  return (
    <nav className="flex w-full h-[65px] justify-between items-center p-2 z-20">
      <Link to="/" className="flex items-center pt-2">
        <img src={logo} width="65" height="65" alt="logo" />
        <h1 className="text-xl md:text-2xl font-bold ml-1 text-purple-800">Carbs on Plate</h1>
      </Link>
    </nav>
  );
};
