import { Footer } from "../components/Footer";
import { Main } from "../components/Main";
import { Navbar } from "../components/Navbar";
import getOnGooglePlay from "./../assets/google-play-badge.webp";

export const HomePage: React.FC = () => {
  const cards = [
    {
      title: "Meal planning",
      description:
        "Plan meals for the days ahead, copy repeating meals, and make adjustments",
    },
    {
      title: "Common products",
      description:
        "Search a collection of common unbranded products like fruit and vegetables",
    },
    {
      title: "Open Food Facts products",
      description:
        "Search a crowdsourced database of branded products. Scan barcodes to find products",
    },
    {
      title: "Products management",
      description:
        'Create and manage your own "My Products" library of products',
    },
    {
      title: "Statistics & Insights",
      description:
        "Get useful insights from the plates you log; proportion of consumed sugar, balanced diet etc.",
    },
  ];
  return (
    <>
      <Navbar />
      <Main>
        <header className=" p-2 md:p-4">
          <h1 className="font-bold text-5xl md:text-6xl lg:text-8xl mb-6">
            Carb counting simple.
          </h1>
          <h2 className="text-gray-800 text-xl md:text-2xl lg:text-3xl leading-tight">
            Carbs on Plate (CoP) is a simple carb management Android application
            designed to help diabetics with the critical and sometimes tedious
            daily tasks of counting carbs. It provides a great overview of carbs
            consumed over time, sugar amounts, and other nutrient information
            that can help you eat a balanced diet.
          </h2>
        </header>
        <section>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 my-8">
            {cards.map((card, i) => (
              <div key={i} className="flex flex-col items-center mb-6">
                <div className="flex min-w-[320px] min-h-[320px] max-w-[320px] max-h-[320px] bg-white relative shadow-2xl hover:shadow-gray-600/70 shadow-gray-600/60 rounded-full px-2 m-1 border-[1px] border-gray-200">
                  <div className="flex flex-col items-center justify-center -mt-8 p-2 z-50">
                    {i === 0 && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-12 h-12"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                        />
                      </svg>
                    )}
                    {i === 1 && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-12 h-12"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
                        />
                      </svg>
                    )}
                    {i === 2 && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-12 h-12 mt-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                        />
                      </svg>
                    )}
                    {i === 3 && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-12 h-12"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z"
                        />
                      </svg>
                    )}
                    {i === 4 && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-12 h-12 mt-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z"
                        />
                      </svg>
                    )}
                    <div className="font-bold text-xl mt-4 p-2">
                      {card.title}
                    </div>
                    <div className="text-gray-600 text-lg py-4 px-6 text-center">
                      {card.description}
                    </div>
                  </div>
                  <div className="top-[58px] left-[58px] w-[200px] h-[200px] absolute shadow-gray-300/50 shadow-inner rounded-full z-40"></div>
                </div>
              </div>
            ))}
          </div>
        </section>
        <div className="w-full my-4">
          <a href="https://play.google.com/store/apps/details?id=com.cop.app">
            <img
              className="m-auto cursor-pointer"
              width="250"
              height="96"
              src={getOnGooglePlay}
              alt="get app on google play"
            />
          </a>
        </div>
      </Main>
      <Footer />
    </>
  );
};
