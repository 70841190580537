import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HomePage } from "./pages/HomePage";
import { UserGuidePage } from "./pages/UserGuidePage";
import { PrivacyPolicyPage } from "./pages/PrivacyPolicyPage";
import splat from "./assets/splat.png";
import { PrivacyPolicyContent } from "./components/PrivacyPolicyContent";
import { UserGuideContent } from "./components/UserGuideContent";

function App() {
  return (
    <Router>
      {/* <div className="flex flex-col min-h-screen bg-gradient-to-b from-primary via-tertiary to-gray-800"> */}
      <div className="flex flex-col min-h-screen">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/privacy-policy-content" element={<PrivacyPolicyContent />} />
          <Route path="/user-guide" element={<UserGuidePage />} />
          <Route path="/user-guide-content" element={<UserGuideContent />} />
        </Routes>
        <div className="absolute top-0 w-full h-full opacity-100 z-10">
          <img
            className="object-cover h-full m-auto"
            src={splat}
            alt="smartphone background"
          />
        </div>
      </div>
    </Router>
  );
}

export default App;
