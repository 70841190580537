import { Footer } from "../components/Footer";
import { Navbar } from "../components/Navbar";
import { PrivacyPolicyContent } from "../components/PrivacyPolicyContent";

export const PrivacyPolicyPage: React.FC = () => {
  return (
    <>
      <Navbar />
      <PrivacyPolicyContent />
      <Footer />
    </>
  );
};
